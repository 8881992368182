import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  MainComponent,
  LogoutComponent,
  AuthenticatedGuard,
  TestersGuard,
  Error404Component,
  ErrorNotLoggedInComponent,
  ErrorCustomMessageComponent,
  ModuleToggle
} from './_shared';
import { ErrorPageComponent } from '@component/error-page/error-page.component';
import { MODULES } from '@core';
import { VacayRequestsComponent } from './_shared/components/vacay-requests/vacay-requests.component';
import { sidebarRoutes } from '@component/side-bar/sidebar.routing';
import { AvaiWarningsModalComponent } from './rostr/overview/components/avai-warnings-modal/avai-warnings-modal.component';
import { RequiredShiftsReportModalComponent } from './rostr/overview/components/required-shifts-report-modal/required-shifts-report-modal.component';

const routes: Routes = [
  {
    path: MODULES.AGENDA,
    component: MainComponent,
    canActivate: [AuthenticatedGuard, TestersGuard, ModuleToggle],
    loadChildren: () => import('./agenda/agenda.module').then(m => m.AgendaModule)
  },
  {
    path: MODULES.ARGUS,
    component: MainComponent,
    canActivate: [AuthenticatedGuard, TestersGuard, ModuleToggle],
    loadChildren: () => import('./argus/argus.module').then(m => m.ArgusModule)
  },
  // {
  //   path: MODULES.BREAKR,
  //   component: MainComponent,
  //   canActivate: [AuthenticatedGuard, TestersGuard],
  //   loadChildren: () => import('./breakr/breakr.module').then(m => m.BreakrModule)
  // },
  {
    path: MODULES.ROSTR,
    component: MainComponent,
    canActivate: [AuthenticatedGuard, TestersGuard, ModuleToggle],
    loadChildren: () => import('./rostr/rostr.module').then(m => m.RostrModule)
  },
  {
    path: MODULES.SETTINGS,
    component: MainComponent,
    canActivate: [AuthenticatedGuard, TestersGuard, ModuleToggle],
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: MODULES.VACAY,
    component: MainComponent,
    canActivate: [AuthenticatedGuard, TestersGuard, ModuleToggle],
    loadChildren: () => import('./vacay/vacay.module').then(m => m.VacayModule)
  },
  {
    path: MODULES.PLANNING,
    component: MainComponent,
    canActivate: [AuthenticatedGuard, TestersGuard, ModuleToggle],
    loadChildren: () => import('./planning/planning.module').then(m => m.PlanningModule)
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'avai-warning',
    component: AvaiWarningsModalComponent,
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'required-shifts-report',
    component: RequiredShiftsReportModalComponent,
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'vacay-requests',
    title: 'Vacay Requests - Horizon',
    component: VacayRequestsComponent,
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'error',
    component: ErrorPageComponent
  },
  {
    path: 'error-not-logged-in',
    component: ErrorNotLoggedInComponent
  },
  {
    path: 'error-404',
    component: Error404Component
  },
  {
    path: 'error/:message',
    component: ErrorCustomMessageComponent
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthenticatedGuard, TestersGuard, ModuleToggle],
    loadChildren: () => import('./platform/platform.module').then(m => m.PlatformModule)
  },
  ...sidebarRoutes,
  {
    path: '**',
    redirectTo: '/error-404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
