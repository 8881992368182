import { gql } from 'apollo-angular';

export const createComment = gql`
  mutation createComment($typeId: Int!, $correspondingDay: String!, $correspondingEmployeeId: Int!, $message: String!, $scheduleId: ID) {
    createComment(
      typeId: $typeId
      correspondingDay: $correspondingDay
      correspondingEmployeeId: $correspondingEmployeeId
      message: $message
      scheduleId: $scheduleId
    ) {
      id
      message
      correspondingDay
      correspondingEmployee {
        id
      }
      type {
        id
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export const createComments = gql`
  mutation createComments($input: [CommentInput]) {
    createComments(input: $input) {
      id
    }
  }
`;

export const updateComment = gql`
  mutation updateComment($id: ID!, $typeId: Int!, $correspondingDay: String!, $correspondingEmployeeId: Int!, $message: String!, $scheduleId: ID) {
    updateComment(
      id: $id
      typeId: $typeId
      correspondingDay: $correspondingDay
      correspondingEmployeeId: $correspondingEmployeeId
      message: $message
      scheduleId: $scheduleId
    ) {
      id
    }
  }
`;

export const deleteComment = gql`
  mutation deleteComment($id: ID!) {
    deleteComment(id: $id) {
      id
    }
  }
`;

export const markCommentAsRead = gql`
  mutation markCommentsAsRead($ids: [Int]) {
    markCommentsAsRead(ids: $ids) {
      id
    }
  }
`;

export const unflagUnionRepresentativeCommentMutation = gql`
  mutation unflagUnionRepresentativeComment($id: ID!, $scheduleId: ID) {
    unflagUnionRepresentativeComment(id: $id, scheduleId: $scheduleId) {
      id
    }
  }
`;

export const commentsCreatedSubscription = gql`
  subscription commentsCreated {
    commentsCreated {
      id
      message
      correspondingDay
      correspondingEmployee {
        id
      }
      type {
        id
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export const commentUpdatedSubscription = gql`
  subscription commentUpdated {
    commentUpdated {
      id
      message
      correspondingDay
      correspondingEmployee {
        id
      }
      type {
        id
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      isRead
    }
  }
`;

export const commentDeletedSubscription = gql`
  subscription commentDeleted {
    commentDeleted {
      id
      message
      correspondingDay
      correspondingEmployee {
        id
      }
      type {
        id
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;
