import { gql } from 'apollo-angular';

/**
 * Fragments
 */
const requestFragment = gql`
  fragment RequestData on VacayRequest {
    id
    code
    iDate {
      date
    }
    date
    createdAt
    createdBy {
      firstName
      lastName
    }
    shiftAlias {
      id
      type
      value
    }
    shiftCode {
      id
      code
    }
    vacayRequestBatch {
      id
      comment
      dateFrom
      dateTo
    }
    data
  }
`;

/**
 * Queries
 */
export const loadAdditionalData = gql`
  query loadAdditionalData($active: Boolean!) {
    shiftAliases(active: $active) {
      id
      type
      value
      connectionStatuses {
        second {
          id
          alias
        }
        status {
          id
          value
          fgColor
          bgColor
        }
        color
      }
    }
  }
`;

export const loadSchedules = gql`
  query loadSchedules($dateFrom: String) {
    vacaySchedules(dateFrom: $dateFrom) {
      id
      name
      dateFrom
      dateTo
      isCurrent
      isUpcoming
      isScheduleLockedInVacay
      isScheduleLockedInAgenda
      lockedVacayDays {
        day
      }
      vacayStatuses {
        employee {
          id
        }
        id
        isCompleted
      }
      state
      updatedAt
    }
  }
`;

export const loadMasterplans = gql`
  query loadMasterplans($employee: Int!, $schedules: [Int]) {
    masterplanAssignedActivities(employee: $employee, schedules: $schedules) {
      id
      code
      iDate {
        date
      }
      scheduleId
      shiftAlias {
        id
        type
        value
      }
    }
  }
`;

export const loadRequests = gql`
  query loadRequests($employee: Int!) {
    vacayRequests(employee: $employee) {
      ...RequestData
    }
  }
  ${requestFragment}
`;

export const loadDaysIncluded = gql`
  query loadDaysIncluded($scheduleId: Int!, $employeeId: Int!) {
    vacayScheduleDaysIncluded(scheduleId: $scheduleId, employeeId: $employeeId)
  }
`;

/**
 * Mutations
 */
export const createRequest = gql`
  mutation createRequest($date: String!, $employee: Int!, $shiftAlias: Int!, $data: JSON) {
    createVacayRequest(date: $date, employee: $employee, shiftAlias: $shiftAlias, data: $data) {
      id
    }
  }
`;

export const updateRequest = gql`
  mutation updateRequest($id: Int!, $date: String, $shiftAlias: Int, $data: JSON) {
    updateVacayRequest(id: $id, date: $date, shiftAlias: $shiftAlias, data: $data) {
      id
    }
  }
`;

export const createVacayScheduleStatus = gql`
  mutation createVacayScheduleStatus($employee: Int!, $schedule: Int!, $isCompleted: Boolean!) {
    createVacayScheduleStatus(employee: $employee, schedule: $schedule, isCompleted: $isCompleted) {
      id
      employee {
        id
      }
      schedule {
        id
      }
      isCompleted
    }
  }
`;

export const updateVacayScheduleStatus = gql`
  mutation updateVacayScheduleStatus($id: Int!, $isCompleted: Boolean!) {
    updateVacayScheduleStatus(id: $id, isCompleted: $isCompleted) {
      id
      employee {
        id
      }
      schedule {
        id
      }
      isCompleted
    }
  }
`;

export const deleteRequest = gql`
  mutation deleteRequest($id: Int!) {
    deleteVacayRequest(id: $id) {
      id
    }
  }
`;

/**
 * Subscriptions
 */

export const subscriptionCreateRequest = gql`
  subscription subscriptionCreateRequest($employee: Int!) {
    vacayRequestCreated(employee: $employee) {
      ...RequestData
    }
  }
  ${requestFragment}
`;

export const subscriptionUpdateRequest = gql`
  subscription subscriptionUpdateRequest($employee: Int!) {
    vacayRequestUpdated(employee: $employee) {
      ...RequestData
    }
  }
  ${requestFragment}
`;

export const subscriptionDeleteRequest = gql`
  subscription subscriptionDeleteRequest($employee: Int!) {
    vacayRequestDeleted(employee: $employee) {
      id
    }
  }
`;

export const loadScheduleAssignedActivities = gql`
  query loadScheduleAssignedActivities($employee: Int!, $schedule: Int!) {
    scheduleAssignedActivities(employee: $employee, schedule: $schedule) {
      id
      code
      dateFrom
      dateTo
      date
      scheduleId
      duration
      shiftAlias {
        alias
      }
      type
      parentType
      parentId
    }
  }
`;
