import { BaseModel } from '../base.model';

import { ShiftConnectionHealthStatusModel } from './shift-connection-health-status';
import { ShiftAliasModelData } from '@interfaces';

export class ShiftAliasModel extends BaseModel {

  public timeFrom: string;
  public timeTo: string;
  public alias: string;
  public value: string;
  public active: boolean;

  public start: number;
  public window: number;
  public duration: number;
  public type: string;

  protected _connectionStatuses: ShiftConnectionHealthStatusModel[] = [];
  protected _mapConnectionStatuses = new Map<number, ShiftConnectionHealthStatusModel>();

  constructor(data: ShiftAliasModelData = {}) {
    super(data);

    this.timeFrom = data.timeFrom || '';
    this.timeTo = data.timeTo || '';
    this.alias = data.alias || '';
    this.value = data.value || '';
    this.active = data.active || false;

    this.start = data.start || null;
    this.window = data.window || null;
    this.duration = data.duration || null;
    this.type = data.type || 'sector';

    this.connectionStatuses = data.connectionStatuses;
  }

  set connectionStatuses(statuses) {
    if (!statuses) {
      this._connectionStatuses = [];
      return;
    }

    this._connectionStatuses = statuses.map((data) => {
      const status = new ShiftConnectionHealthStatusModel(data);

      if (status.second && status.second.id) {
        this._mapConnectionStatuses.set(status.second.id, status);
      }

      return status;
    });
  }

  get connectionStatuses() {
    return this._connectionStatuses;
  }

  get mapConnectionStatuses() {
    return this._mapConnectionStatuses;
  }

  get isDayOff() {
    return this.type === 'dayOff';
  }

  get isVacation() {
    return this.type === 'vacation';
  }

}
