import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { ENVIRONMENTS } from '@core';
import { environment } from '@src/environments/environment';
import { AppInsightsService, DialogService } from '@services';
import { Router } from '@angular/router';

const verboseEnvironments = [ENVIRONMENTS.LOCAL, ENVIRONMENTS.DEV, ENVIRONMENTS.STAGE];

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(
    private appInsightsService: AppInsightsService,
    private router: Router,
    private dialogService: DialogService
  ) {}

  public handle(error: Error | string) {
    this.appInsightsService.logError(this.getErrorMessage(error));

    if (verboseEnvironments.includes(environment.env)) {
      this.handleErrorVerbosely(error);
    } else {
      this.handleError();
    }

    return EMPTY;
  }

  public displayErrorMessage(error: Error) {
    this.appInsightsService.logError(this.getErrorMessage(error));

    this.dialogService.snackBar(error.message);
    return EMPTY;
  }

  private handleError() {
    this.router.navigate(['error']);
  }

  private handleErrorVerbosely(error: Error | string) {
    this.dialogService.snackBar(this.getErrorMessage(error), { classes: ['break-spaces'] });
  }

  private getErrorMessage(error: Error | string) {
    return error instanceof Error ? `${error.message} \n${error.stack}` : error;
  }
}
