import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { ErrorService } from '@services';
import {
  assignedActivitiesChangedSubscription,
  rostrClearAssignedActivities,
  rostrSwapAssignedActivities,
  rostrCreateAssignedActivities,
  rostrUpsertAssignedActivity,
  rostrDeleteAssignedActivities,
  rostrUpdateAssignedActivities,
  scheduleClearedSubscription,
  scheduleEmployeesAssignmentsChangedSubscription,
  scheduleEmployeesOrderChangedSubscription,
  rostrAssignConstellation
} from '../queries/shifts-assignments.queries';
import { catchError, map } from 'rxjs/operators';
import { SwapActivityPayload } from '../payloads/swap-activities.payload';
import { ClearAssignedActivitiesPayload } from '../payloads/clear-assigned-activities.payload';
import { UpsertActivityPayload } from '../payloads/upsert-activity.payload';
import { LiveUpdateBulkResult } from '../../dto/live-update-bulk-result.model';
import { LiveScheduleData } from '../../dto/live-update-result.model';
import { AssignConstellationPayload } from '../payloads/assign-constellation.payload';

@Injectable({ providedIn: 'root' })
export class ShiftsAssignmentsAPIService {
  constructor(
    private readonly apollo: Apollo,
    private readonly errorService: ErrorService
  ) {}

  public insertActivities(payload: UpsertActivityPayload[]): Observable<void> {
    return this.apollo
      .mutate({
        mutation: rostrCreateAssignedActivities,
        variables: { input: payload }
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public upsertActivity(payload: UpsertActivityPayload): Observable<void> {
    return this.apollo
      .mutate({
        mutation: rostrUpsertAssignedActivity,
        variables: payload
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public deleteActivities(payload: number[]): Observable<void> {
    return this.apollo
      .mutate({
        mutation: rostrDeleteAssignedActivities,
        variables: { input: payload }
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public swapActivities(payload: SwapActivityPayload): Observable<void> {
    return this.apollo
      .mutate({
        mutation: rostrSwapAssignedActivities,
        variables: payload
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public assignConstellation(payload: AssignConstellationPayload): Observable<void> {
    return this.apollo
      .mutate({
        mutation: rostrAssignConstellation,
        variables: payload
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public toggleLockActivities(activitiesIds: number[], isLocked: boolean): Observable<void> {
    return this.apollo
      .mutate({
        mutation: rostrUpdateAssignedActivities,
        variables: {
          input: activitiesIds.map(activityId => {
            return {
              id: activityId,
              data: {
                isLocked: isLocked,
                _partial: true
              }
            };
          })
        }
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public clearAssignedActivities(payload: ClearAssignedActivitiesPayload): Observable<void> {
    return this.apollo
      .mutate({
        mutation: rostrClearAssignedActivities,
        variables: payload
      })
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      ) as Observable<void>;
  }

  public onActivitiesChanged$(scheduleId: number): Observable<LiveUpdateBulkResult> {
    return this.apollo
      .subscribe({
        query: assignedActivitiesChangedSubscription,
        variables: {
          scheduleId
        }
      })
      .pipe(
        map((response: { data: { assignedActivitiesChanged: LiveUpdateBulkResult } }) => {
          return response.data.assignedActivitiesChanged;
        })
      )
      .pipe(
        catchError(err => {
          return this.errorService.handle(err);
        })
      );
  }

  public onScheduleCleared$(scheduleId: number): Observable<LiveScheduleData> {
    return this.apollo
      .subscribe({
        query: scheduleClearedSubscription,
        variables: {
          scheduleId: scheduleId
        }
      })
      .pipe(map((response: { data: { scheduleCleared: LiveScheduleData } }) => response.data.scheduleCleared));
  }

  public onScheduleEmployeesOrderChanged$(scheduleId: number): Observable<LiveScheduleData> {
    return this.apollo
      .subscribe({
        query: scheduleEmployeesOrderChangedSubscription,
        variables: {
          scheduleId: scheduleId
        }
      })
      .pipe(map((response: { data: { scheduleEmployeesOrderChanged: LiveScheduleData } }) => response.data.scheduleEmployeesOrderChanged));
  }

  public onScheduleEmployeesAssignmentsChanged$(scheduleId: number): Observable<LiveScheduleData> {
    return this.apollo
      .subscribe({
        query: scheduleEmployeesAssignmentsChangedSubscription,
        variables: {
          scheduleId: scheduleId
        }
      })
      .pipe(map((response: { data: { scheduleEmployeesAssignmentsChanged: LiveScheduleData } }) => response.data.scheduleEmployeesAssignmentsChanged));
  }
}
