import { BaseModel } from '../base.model';


export class HealthStatusModel extends BaseModel {

  public bgColor: string;
  public fgColor: string;
  public healthIndication: string;

  public value: number;
  public active: boolean;
  public color: string;
  public title: string;

  constructor(data: any = {}) {
    super(data);

 
    this.bgColor = data.bgColor || '#ffffff';
    this.fgColor = data.fgColor || '#000000';
    this.healthIndication = data.healthIndication || 'No health indication';

    this.value = data.value || 0;
    this.active = data.active || false;
    this.color = data.color || '';
    this.title = data.title || '';
  }
}
