import { EMPLOYEE, AGENDA, VACAY, ARGUS, SETTINGS, ROSTR, PLANNING } from '@shared/helpers/router-paths';
import { MODULES } from '@core';
import { SettingsKey } from '../models/settings/settings-key';
import { IRouteChild, IRouteConfig, IRouteModule } from '@interfaces';

import { roleRoutesConfiguration } from './role-routes-configuration';

export const routes = [MODULES.VACAY, MODULES.ROSTR, MODULES.AGENDA, MODULES.PLANNING, MODULES.PLATFORM, MODULES.ARGUS, MODULES.SETTINGS /*MODULES.BREAKR*/];
export const routeConfig: IRouteConfig = {
  getModule(path: string): IRouteModule {
    return this.getModules().find(module => module.path === path);
  },
  getActiveModule(path: string): IRouteModule {
    const modulePath = routeConfig.hasOwnProperty(path) ? path : MODULES.PLATFORM;
    return this[modulePath];
  },
  getChildren(path: string, roles: string[]): IRouteChild[] {
    const roleRoutes = roleRoutesConfiguration.filter(rc => roles.some(role => rc.roles.includes(role)));
    const modules = roleRoutes.flatMap(roleRoute => roleRoute.routes);
    const module = modules.filter(route => modules.includes(route)).find(item => item.module === path);

    return this[path].children.filter(child => (module.pages ? module.pages.includes(child.path) : true));
  },
  getModules(): IRouteModule[] {
    // some modules are not availeable for V1
    return routes.map(item => this[item]);
  },
  getPermittedModule(roles: string[]): IRouteModule[] {
    const roleRoutes = roleRoutesConfiguration.filter(rc => roles.some(role => rc.roles.includes(role)));
    const modules = roleRoutes.flatMap(roleRoute => roleRoute.routes.map(x => x.module));
    return routes.filter(route => modules.includes(route)).map(item => this[item]);
  },
  getToggledOnModules(): IRouteModule[] {
    return this.getModules().filter(module => module.moduleToggle.active);
  },

  setModuleToggle(path: string, state: boolean): void {
    const moduleToActivate = this.getModules().find(module => module.path === path);
    moduleToActivate.moduleToggle.active = state;
  },

  vacay: {
    title: 'VACAY',
    path: MODULES.VACAY,
    icon: 'airplanemode_active',
    children: [{ path: 'schedule', url: VACAY.SCHEDULE, title: 'Schedule' }],
    moduleToggle: {
      settingIdentifier: SettingsKey.VacayToggle,
      active: true
    }
  },
  rostr: {
    title: 'ROSTr',
    path: MODULES.ROSTR,
    icon: 'dashboard',
    children: [
      { path: 'overview', url: ROSTR.OVERVIEW, title: 'Overview' },
      { path: 'schedules', url: ROSTR.SCHEDULES, title: 'Schedules' }
    ],
    moduleToggle: {
      settingIdentifier: null,
      active: true
    }
  },
  entity: {
    title: 'Avinor',
    path: MODULES.PLATFORM,
    icon: 'language',
    children: [
      { path: 'employee', url: EMPLOYEE.OVERVIEW, title: 'Employees' },
      { path: 'group', url: EMPLOYEE.GROUP, title: 'My groups' }
      // { path: 'profile', url: EMPLOYEE.PROFILE, title: 'Profile' },
      // { path: 'medical', url: EMPLOYEE.MEDICAL, title: 'Medical' }
    ],
    moduleToggle: {
      settingIdentifier: null,
      active: true
    }
  },
  agenda: {
    title: 'Agenda',
    path: MODULES.AGENDA,
    icon: 'event_available',
    children: [
      { path: 'overview', url: AGENDA.OVERVIEW, title: 'Overview' },
      { path: 'timeline', url: AGENDA.TIMELINE, title: 'Timeline' },
      { path: 'projects', url: AGENDA.PROJECTS, title: 'Projects' },
      { path: 'objectives', url: AGENDA.OBJECTIVES, title: 'Objectives' },
      { path: 'unspecified', url: AGENDA.UNSPECIFIED, title: 'Unspecified' }
    ],
    moduleToggle: {
      settingIdentifier: null,
      active: true
    }
  },
  planning: {
    title: 'Planning',
    path: MODULES.PLANNING,
    icon: 'view_agenda',
    children: [
      { path: 'overview', url: PLANNING.MASTERPLAN_OVERVIEW, title: 'Masterplan overview' },
      { path: 'list', url: PLANNING.MASTERPLAN_LIST, title: 'Masterplan list' },
      { path: 'editor', url: PLANNING.MASTERPLAN_EDITOR, title: 'Masterplan editor' },
      { path: 'preview', url: PLANNING.MASTERPLAN_PREVIEW, title: 'Masterplan preview' },
      { path: 'requirements-editor', url: PLANNING.REQUIREMENTS_EDITOR, title: 'Requirements editor' }
    ],
    moduleToggle: {
      settingIdentifier: null,
      active: true
    }
  },
  argus: {
    title: 'Argus',
    path: MODULES.ARGUS,
    icon: 'camera',
    children: [
      { path: 'overview', url: ARGUS.OVERVIEW, title: 'Overview' },
      { path: 'report', url: ARGUS.REPORT, title: 'Report' }
    ],
    moduleToggle: {
      settingIdentifier: SettingsKey.ArgusToggle,
      active: false
    }
  },
  settings: {
    title: 'Settings',
    path: MODULES.SETTINGS,
    icon: 'settings',
    children: [
      { path: 'holidays', url: SETTINGS.HOLIDAYS, title: 'Holidays' },
      { path: 'health', url: SETTINGS.HEALTH, title: 'Health' },
      { path: 'sectors', url: SETTINGS.SECTORS, title: 'Sectors' },
      { path: 'organization', url: SETTINGS.ORGANIZATION, title: 'Organization' },
      { path: 'shifts', url: SETTINGS.SHIFTS, title: 'Global shifts' },
      { path: 'shift-aliases', url: SETTINGS.SHIFT_ALIASES, title: 'Shift aliases' },
      { path: 'maintenance', url: SETTINGS.MAINTENANCE, title: 'Maintenance' },
      { path: 'skills', url: SETTINGS.SKILLS, title: 'Skills' },
      { path: 'licenses', url: SETTINGS.LICENSES, title: 'Licenses' },
      { path: 'endorsements', url: SETTINGS.ENDORSEMENTS, title: 'Endorsements' }
    ],
    moduleToggle: {
      settingIdentifier: null,
      active: true
    }
  }
};
