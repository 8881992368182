import { gql } from 'apollo-angular';

export const fragments = {
  alias: gql`
    fragment ShiftAliasInfo on ShiftAlias {
      id
      timeFrom
      timeTo
      start
      window
      duration
      alias
      value
      active
      type
      connectionStatuses {
        id
        first { id }
        second { id }
        color
        status { id }
      }
    }
  `,
  health_relation: gql`
    fragment HealthRelationInfo on ShiftConnectionHealthStatus {
      id
      first { id }
      second { id }
      color
      status { id }
    }
  `
};

export const loadShiftAliases = gql`
  query loadShiftAliases($targetName: String!, $targetId: Int!, $type: String) {
    shiftAliases(targetName: $targetName, targetId: $targetId, type: $type) {
      ...ShiftAliasInfo
    }
  }
  ${fragments.alias}
`;

export const activeHealthStatuses = gql`
  query activeHealthStatuses {
    healthStatuses {
      id
      color
      title
    }
  }
`;

export const createShiftConnectionHealthStatus = gql`
  mutation createShiftConnectionHealthStatus(
    $first: Int,
    $second: Int,
    $status: Int
  ) {
    createShiftConnectionHealthStatus(
      first: $first,
      second: $second,
      status: $status
    ) {
      ...HealthRelationInfo
    }
  }
  ${fragments.health_relation}
`;

export const updateShiftConnectionHealthStatus = gql`
  mutation updateShiftConnectionHealthStatus($id: Int!, $status: Int) {
    updateShiftConnectionHealthStatus(id: $id, status: $status) {
      ...HealthRelationInfo
    }
  }
  ${fragments.health_relation}
`;

export const bulkShiftAliasOperation = gql`
  mutation bulkOperation(
    $created: [ShiftAliasInput],
    $updated: [ShiftAliasInput],
    $deleted: [Int]
  ) {
    bulkShiftAliasOperation(
      created: $created,
      updated: $updated,
      deleted: $deleted
    ) {
      created {
        ...ShiftAliasInfo
      }

      updated {
        ...ShiftAliasInfo
      }

      deleted {
        id
      }
    }
  }
  ${fragments.alias}
`;


