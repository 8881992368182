import { HolidayModel, MasterplanAssignedActivityModel, ScheduleModel, ShiftAliasModel, VacayRequestModel } from '@models';
import { AssignedShift, Schedule, ScheduleDay } from '@app/vacay/interfaces';
import { ScheduleState } from '@model/schedules/schedule-state.model';
import * as moment from 'moment-mini';
import { enumerateMomentDates } from '@helpers';
import { HolidaysService } from '@services';
import { DateRangeMoment } from '@interfaces';
import { ConversationPanelComment } from '@component/conversation-panel/models';
import { MasterplanActivity } from './timeline';
import { ScheduleAssignedActivity } from '../interfaces/schedule-assigned-activity';
import { AgendaShiftRequest } from './agenda/agenda-shift-request';
import { CommentList } from '@component/conversation-panel/models/comment-list';

export const ScheduleKeyFormat = 'YYYY-MM-DD';

export class VacayScheduleModel implements Schedule {
  public id: number;
  public dateFrom: moment.Moment;
  public dateTo: moment.Moment;
  public name: string;
  public days: Map<string, ScheduleDay>;
  public completionMark?: { isCompleted: boolean; id: number };
  public state: ScheduleState;
  public updatedAt?: moment.Moment;
  public get scheduleDateRangeMoment(): DateRangeMoment {
    return { dateFrom: this.dateFrom, dateTo: this.dateTo };
  }
  public allDataLoaded = false;
  public userId: number;

  public static fromScheduleModel(scheduleModel: ScheduleModel, userId: number): VacayScheduleModel {
    const result = new VacayScheduleModel();
    result.days = result.populateDays(scheduleModel, userId);
    result.id = scheduleModel.id;
    result.name = scheduleModel.name;
    result.state = scheduleModel.state;
    result.updatedAt = scheduleModel.updatedAt;
    result.dateFrom = scheduleModel.dateFromMoment;
    result.dateTo = scheduleModel.dateToMoment;
    result.completionMark = scheduleModel.vacayStatuses.find(el => el.employeeId === userId);
    result.userId = userId;
    return result;
  }

  private populateDays(schedule: ScheduleModel, userId: number): Map<string, ScheduleDay> {
    const result = new Map<string, ScheduleDay>();
    enumerateMomentDates(schedule.dateFromMoment, schedule.dateToMoment).map((momentDate: moment.Moment) => {
      result.set(momentDate.format(ScheduleKeyFormat), {
        date: momentDate,
        aliasId: null,
        isUnhealthyConnection: false,
        masterplan: '',
        masterplanAlias: null,
        masterplanActivity: null,
        wish: null,
        vacayShiftRequests: [],
        isHoliday: false,
        isDayOff: false,
        isVacation: false,
        isScheduleLocked: false,
        isLocked: this.isDayLockedInSchedule(schedule, momentDate),
        commentList: new CommentList([], userId),
        hasVacayRequest: false,
        vacayRequest: null,
        activity: null,
        health: {},
        isIncluded: false,
        shift: null,
        scheduleState: schedule.state,
        agendaCount: 0
      });
    });
    return result;
  }

  private isDayLockedInSchedule(schedule: ScheduleModel, date: moment.Moment) {
    return schedule.lockedVacayDays.some(day => day.isSame(date));
  }

  public copyScheduleForComparison(): VacayScheduleModel {
    const result = new VacayScheduleModel();
    result.id = this.id;
    result.dateFrom = this.dateFrom;
    result.dateTo = this.dateTo;
    result.name = this.name;
    result.state = this.state;
    result.days = this.populateDays(
      {
        dateFromMoment: result.dateFrom,
        dateToMoment: result.dateTo,
        lockedVacayDays: [],
        state: result.state
      } as ScheduleModel,
      this.userId
    );
    result.completionMark = { isCompleted: false, id: 0 };
    return result;
  }

  public setDaysIncluded(daysIncluded: string[]) {
    daysIncluded.forEach(dayIncluded => {
      const day = this.days.get(dayIncluded);
      if (day) {
        day.isIncluded = true;
      }
    });
  }

  public setHolidays(holidays: HolidayModel[]) {
    HolidaysService.holidaysToDatesByDateRange(holidays, this.scheduleDateRangeMoment).forEach(holiday => {
      const day = this.days.get(holiday.format(ScheduleKeyFormat));
      if (day) {
        day.isHoliday = true;
      }
    });
  }

  public setComments(comments: ConversationPanelComment[]) {
    comments.forEach((comment: ConversationPanelComment) => {
      const day = this.days.get(comment.timestamp);
      if (day) {
        day.commentList.addComment(comment);
      }
    });
  }

  public deleteComment(comment: ConversationPanelComment) {
    const day = this.days.get(comment.timestamp);
    if (day) {
      day.commentList.deleteComment(comment);
    }
  }

  public updateComment(comment: ConversationPanelComment) {
    const day = this.days.get(comment.timestamp);
    if (!day) {
      return;
    }

    day.commentList.updateComment(comment);
  }

  public setMasterplanShifts(masterplanShifts: MasterplanAssignedActivityModel[]) {
    masterplanShifts.forEach((shift: MasterplanAssignedActivityModel) => {
      const day = this.days.get(shift.iDate.date.format(ScheduleKeyFormat));
      if (day) {
        day.masterplan = shift.alias.value;
        day.masterplanAlias = shift.alias;
        day.masterplanActivity = new MasterplanActivity(shift, null); //todo check if the field is used
        this.updateDayAliasConditionally(day);
      }
    });
  }

  public setAssignedShifts(assignedActivities: ScheduleAssignedActivity[], shiftColors: Map<number, string>) {
    assignedActivities.forEach((shift: ScheduleAssignedActivity) => {
      const momentVar = moment.utc(shift.date.toString());
      const day = this.days.get(momentVar.format(ScheduleKeyFormat));

      if (day) {
        const shiftColor = shiftColors.get(shift.id) ?? '#ffffff';
        day.shift = shift as AssignedShift;
        day.shift.color = shiftColor;
      }
    });
  }

  public setWishes(wishes: VacayRequestModel[]) {
    wishes.forEach((wish: VacayRequestModel) => {
      const day = this.days.get(wish.iDate.date.format(ScheduleKeyFormat));
      if (day) {
        if (wish.shiftCode) {
          day.vacayShiftRequests.push({ id: wish.id, shiftCode: wish.shiftCode.code });
        } else {
          day.wish = {
            shiftCode: wish.code,
            isWill: wish.groupedWishes.available,
            alias: wish.alias
          };
          day.vacayRequest = wish;
        }

        day.hasVacayRequest = true;
        this.updateDayAliasConditionally(day);
      }
    });
  }

  public setAgendas(agendas: AgendaShiftRequest[]) {
    agendas.forEach((agenda: AgendaShiftRequest) => {
      const day = this.days.get(agenda.date.format(ScheduleKeyFormat));
      if (day) {
        day.agendaCount++;
      }
    });
  }

  public removeWishFromDay(id: number) {
    this.days.forEach(day => {
      if (day.vacayRequest?.id === id) {
        day.wish = null;
        day.vacayRequest = null;
        this.updateDayAliasConditionally(day);
      }
      const index = day.vacayShiftRequests.findIndex(request => request.id === id);
      if (index !== -1) {
        day.vacayShiftRequests.splice(index, 1);
      }

      if (!day.vacayShiftRequests.length && !day.wish) {
        day.hasVacayRequest = false;
      }
    });
  }

  public setWishForDay(wish: VacayRequestModel) {
    const day = this.days.get(wish.iDate.date.format(ScheduleKeyFormat));
    if (day) {
      if (wish.shiftCode) {
        day.vacayShiftRequests.push({ id: wish.id, shiftCode: wish.shiftCode.code });
      } else {
        day.wish = {
          shiftCode: wish.code,
          isWill: wish.groupedWishes.available,
          alias: wish.alias
        };
        day.vacayRequest = wish;
      }
      day.hasVacayRequest = true;
      this.updateDayAliasConditionally(day);
    }
  }

  private updateDayAliasConditionally(day: ScheduleDay) {
    if (day.wish) {
      this.updateDayAlias(day, day.wish.alias);
    } else if (day.masterplanAlias) {
      this.updateDayAlias(day, day.masterplanAlias);
    } else {
      day.isDayOff = false;
      day.isVacation = false;
      day.aliasId = null;
    }
  }

  private updateDayAlias(day: ScheduleDay, alias: ShiftAliasModel) {
    if (!alias) {
      return;
    }

    if (day.aliasId !== alias.id) {
      day.isDayOff = alias.isDayOff;
      day.isVacation = alias.isVacation;
      day.aliasId = alias.id;
    }
  }
}
