import { BaseModel } from '../base.model';
import { ShiftAliasModel } from './shift-alias';
import { HealthStatusModel } from './health-status';

export class ShiftConnectionHealthStatusModel extends BaseModel {

  public first: ShiftAliasModel;
  public second: ShiftAliasModel;
  public status: HealthStatusModel;
  public color: string;

  constructor(data: any = {}) {
    super(data);

    this.first = (data.first && new ShiftAliasModel(data.first)) || undefined;
    this.second = (data.second && new ShiftAliasModel(data.second)) || undefined;
    this.status = (data.status && new HealthStatusModel(data.status)) || undefined;
    this.color = data.color || '';
  }

}
