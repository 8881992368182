<div class="dialog-container">
  <h2 mat-dialog-title>{{ data.title }}</h2>

  <mat-dialog-content [class]="data.customClass ? data.customClass : ''" [innerHTML]="data.body"> </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button color="accent" (click)="onNoClick($event)" *ngIf="!data.isAlert">
      {{ data.config && data.config.cancelBtn ? data.config.cancelBtn : 'Cancel' }}
    </button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" (click)="onOkClick($event)" autofocus>
      {{ data.config && data.config.okBtn ? data.config.okBtn : 'OK' }}
    </button>
  </mat-dialog-actions>
</div>
