import { ISO_DATE_ONLY_FORMAT } from '@helpers';
import moment from 'moment-mini';
import { CommentResult } from './comment-result.model';
import { CommentType } from './comment-type';

export class ConversationPanelComment {
  public id: number;
  public message: string;
  public timestamp: string;
  public type: CommentType;
  public authorId: number;
  public author: string;
  public createdAt: string;
  public isRead: boolean;
  public employeeId: number;

  constructor(createCommentResult: CommentResult) {
    this.id = +createCommentResult.id;
    this.message = createCommentResult.message;
    this.timestamp = moment(createCommentResult.correspondingDay).format(ISO_DATE_ONLY_FORMAT);
    this.author = `${createCommentResult.createdBy.firstName} ${createCommentResult.createdBy.lastName}`;
    this.authorId = createCommentResult.createdBy.id;
    this.createdAt = createCommentResult.createdAt;
    this.type = createCommentResult.type.id;
    this.isRead = createCommentResult.isRead || false;
    this.employeeId = createCommentResult.correspondingEmployee.id;
  }
}
