import { Observable, forkJoin, of, BehaviorSubject, combineLatest, pipe } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ScheduleModel, HolidayModel, VacayRequestModel, ShiftAliasModel, HealthStatusModel, MasterplanAssignedActivityModel } from '@models';
import { HolidaysService, ShiftService } from '@services';
import { CommentsService } from '@component/comments-tooltip/services/comments.service';
import { ScheduleService } from './schedule.service';
import { AuthenticationService } from '@app/_auth/services/authentication.service';
import { ScheduleAssignedActivity } from '../interfaces/schedule-assigned-activity';
import { VacayScheduleModel } from '../models/vacay-schedule-model';
import { ScheduleState } from '@model/schedules/schedule-state.model';
import { ConversationPanelComment } from '@component/conversation-panel/models';
import { AgendaService } from './agenda.service';
import { AgendaShiftRequest } from '../models/agenda/agenda-shift-request';
import * as moment from 'moment-mini';

@Injectable({
  providedIn: 'root'
})
export class VacayScheduleService {
  constructor(
    private readonly scheduleService: ScheduleService,
    private readonly holidayService: HolidaysService,
    private readonly commentsService: CommentsService,
    private readonly authService: AuthenticationService,
    private readonly shiftService: ShiftService,
    private readonly agendaService: AgendaService
  ) {}

  public shiftAliases: ShiftAliasModel[];
  public holidays$: BehaviorSubject<HolidayModel[]> = new BehaviorSubject<HolidayModel[]>([]);
  private aliasesMap: Map<number, ShiftAliasModel>;
  public shiftColors$: BehaviorSubject<Map<number, string>> = new BehaviorSubject<Map<number, string>>(new Map<number, string>());
  public wishes$: BehaviorSubject<VacayRequestModel[]> = new BehaviorSubject<VacayRequestModel[]>([]);

  public healthStatusesMap: Map<number, HealthStatusModel>;

  private readonly schedules$$ = new BehaviorSubject<VacayScheduleModel[]>([]);

  public readonly schudules$ = this.schedules$$.asObservable();

  public getWishesForDay$(date: moment.Moment): Observable<VacayRequestModel[]> {
    return this.wishes$.pipe(map(wishes => wishes.filter(wish => wish.shiftCode && date.isSame(wish.date, 'day'))));
  }

  public addWish(wish: VacayRequestModel) {
    this.wishes$.next([...this.wishes$.value, wish]);
  }

  public removeWish(wishId: number) {
    this.wishes$.next(this.wishes$.value.filter(w => w.id !== wishId));
  }

  public loadSchedules(filterUpcomingSchedules = true): Observable<VacayScheduleModel[]> {
    const observables = () => [this.loadAdditionalData(), this.loadShiftColors(), this.loadWishes()];

    return this.scheduleService.loadSchedules(filterUpcomingSchedules).pipe(
      map((schedules: ScheduleModel[]): VacayScheduleModel[] => {
        return schedules.map(scheduleModel => {
          return VacayScheduleModel.fromScheduleModel(scheduleModel, this.authService.user.id);
        });
      }),
      mergeMap(schedules => forkJoin(observables()).pipe(map(() => schedules))),
      mergeMap(schedules => this.loadHolidays().pipe(map(() => schedules))),
      tap(schedules => this.schedules$$.next(schedules))
    );
  }

  public loadScheduleData(schedule: VacayScheduleModel, employeeId: number = this.authService.user.id) {
    const observablesToLoad = [
      this.scheduleService.loadDaysIncluded(schedule.id, employeeId),
      this.holidays$,
      this.commentsService.fetchCommentsForLoggedUser(schedule.dateFrom.toDate(), schedule.dateTo.toDate()),
      this.loadMasterplan(schedule.id, schedule.state),
      this.loadAssignedActivities(schedule.id, employeeId, schedule.state),
      this.shiftColors$,
      this.wishes$,
      this.agendaService.loadAgendaList(schedule.dateFrom, schedule.dateTo, employeeId)
    ];

    return combineLatest(observablesToLoad).pipe(
      tap(([daysIncluded, holidays, comments, masterplanShifts, assignedActivities, shiftColors, wishes, agendas]) => {
        if (schedule.allDataLoaded) {
          return;
        }

        schedule.setDaysIncluded(daysIncluded as string[]);

        schedule.setHolidays(holidays as HolidayModel[]);

        schedule.setComments(comments as ConversationPanelComment[]);

        schedule.setMasterplanShifts(masterplanShifts as MasterplanAssignedActivityModel[]);

        schedule.setAssignedShifts(assignedActivities as ScheduleAssignedActivity[], shiftColors as Map<number, string>);

        schedule.setWishes(wishes as VacayRequestModel[]);

        schedule.setAgendas(agendas as AgendaShiftRequest[]);

        schedule.allDataLoaded = true;
      })
    );
  }

  public loadLimitedScheduleDataForComparison(schedule: VacayScheduleModel, employeeId: number = this.authService.user.id) {
    const observablesToLoad = [
      this.scheduleService.loadDaysIncluded(schedule.id, employeeId),
      this.holidays$,
      this.loadAssignedActivities(schedule.id, employeeId, schedule.state),
      this.shiftColors$
    ];

    return combineLatest(observablesToLoad)
      .pipe(
        tap(([daysIncluded, holidays, assignedActivities, shiftColors]) => {
          schedule.setDaysIncluded(daysIncluded as string[]);

          schedule.setHolidays(holidays as HolidayModel[]);

          schedule.setAssignedShifts(assignedActivities as ScheduleAssignedActivity[], shiftColors as Map<number, string>);
        })
      )
      .pipe(map(() => schedule));
  }

  public loadMasterplanForComparison(schedule: VacayScheduleModel, employeeId: number = this.authService.user.id) {
    const observablesToLoad = [
      this.scheduleService.loadDaysIncluded(schedule.id, employeeId),
      this.holidays$,
      this.loadMasterplan(schedule.id, schedule.state),
      this.shiftColors$
    ];

    return combineLatest(observablesToLoad)
      .pipe(
        tap(([daysIncluded, holidays, masterplanShifts, shiftColors]) => {
          schedule.setDaysIncluded(daysIncluded as string[]);

          schedule.setHolidays(holidays as HolidayModel[]);

          schedule.setMasterplanShifts(masterplanShifts as MasterplanAssignedActivityModel[]);
        })
      )
      .pipe(map(() => schedule));
  }

  private loadMasterplan(scheduleId: number, scheduleState: ScheduleState): Observable<MasterplanAssignedActivityModel[]> {
    if (scheduleState === ScheduleState.OPEN || scheduleState === ScheduleState.PLANNING) {
      return this.scheduleService.loadMasterplans([scheduleId]);
    } else {
      return of([]);
    }
  }

  private loadAssignedActivities(scheduleId: number, employeeId: number, scheduleState: ScheduleState): Observable<ScheduleAssignedActivity[]> {
    if (scheduleState === ScheduleState.DRAFT || scheduleState === ScheduleState.DISTRIBUTED) {
      return this.scheduleService.loadSchedulesAssignedActivities(scheduleId, employeeId);
    } else {
      return of([]);
    }
  }

  private loadAdditionalData(): Observable<ShiftAliasModel[]> {
    if (!this.aliasesMap) {
      return this.scheduleService.loadShiftAliases().pipe(
        tap(shiftAliases => {
          this.aliasesMap = new Map<number, ShiftAliasModel>();
          shiftAliases.forEach((alias: ShiftAliasModel) => this.aliasesMap.set(alias.id, alias));
          this.shiftAliases = shiftAliases;
        })
      );
    }
    return of(null);
  }

  public loadShiftColors() {
    return this.shiftService.getShiftCodeColors().pipe(tap(colorMap => this.shiftColors$.next(colorMap)));
  }

  private loadWishes(): Observable<VacayRequestModel[]> {
    return this.scheduleService.loadRequests().pipe(tap(wishes => this.wishes$.next(wishes)));
  }

  public loadHolidays(): Observable<HolidayModel[]> {
    return this.holidayService.fetchData().pipe(
      tap(holidays => {
        this.holidays$.next(holidays);
      })
    );
  }
}
