<div class="time-picker-input">
  <mat-select [id]="id" name="hours" [disableOptionCentering]="true" [(value)]="hourValue" (selectionChange)="onHoursChange($event)" [disabled]="disabled">
    <mat-option *ngFor="let hour of hours" [value]="hour">
      {{ hour }}
    </mat-option>
  </mat-select>
  <mat-select name="minutes" [disableOptionCentering]="true" [(value)]="minuteValue" (selectionChange)="onMinutesChange($event)" [disabled]="disabled">
    <mat-option *ngFor="let minute of minutes" [value]="minute">
      {{ minute }}
    </mat-option>
  </mat-select>
</div>
