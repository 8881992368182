import { gql } from 'apollo-angular';

export const createVacayRequestBatch = gql`
  mutation CreateVacayRequestBatch($shiftCodeId: Int!, $dateTo: String!, $dateFrom: String!, $comment: String) {
    createVacayRequestBatch(shiftCodeId: $shiftCodeId, dateTo: $dateTo, dateFrom: $dateFrom, comment: $comment) {
      id
    }
  }
`;

export const deleteVacayRequestBatch = gql`
  mutation deleteVacayRequestBatch($id: Int!) {
    deleteVacayRequestBatch(id: $id)
  }
`;

export const getVacayRequestInfoWithBatch = gql`
  query VacayRequest($vacayRequestId: Int!) {
    vacayRequest(id: $vacayRequestId) {
      vacayRequestBatch {
        comment
        id
        dateFrom
        dateTo
        vacayRequests {
          id
        }
      }
      createdBy {
        firstName
        lastName
      }
      updatedAt
      shiftCode {
        code
      }
      shiftAlias {
        value
      }
      date
      dateFrom
      dateTo
      duration
      start
    }
  }
`;

export const vacayRequestsForSchedule = gql`
  query vacayRequestsForSchedule($scheduleId: ID!) {
    vacayRequestsForSchedule(scheduleId: $scheduleId) {
      schedule {
        id
        name
        dateFrom
        dateTo
      }
      requests {
        id
        code
        vacayRequestBatchId
        vacayRequestBatch {
          vacayRequests {
            id
          }
          comment
          dateFrom
          dateTo
        }
        shiftAlias {
          id
          value
          alias
        }
        shiftCode {
          id
          code
          type {
            id
            name
          }
        }
        assignedActivity {
          id
          code
          shiftAlias {
            id
            value
            alias
          }
          type
          data
        }
        employee {
          id
          firstName
          middleName
          lastName
        }
        date
        dateFrom
        dateTo
      }
    }
  }
`;

export const assignedActivityChangedVacaySub = gql`
  subscription assignedActivitiesChanged($scheduleId: Int) {
    assignedActivitiesChanged(scheduleId: $scheduleId) {
      created {
        shift {
          id
          code
          date
          employeeId
          shiftAlias {
            id
            value
          }
          data
          type
        }
      }
      updated {
        shift {
          id
          code
          date
          employeeId
          shiftAlias {
            id
            value
          }
          data
          type
        }
        previousEmployeeId
      }
      deleted {
        shift {
          id
          code
          date
          employeeId
          shiftAlias {
            id
            value
          }
          data
          type
        }
      }
      swapped {
        shift {
          id
          code
          date
          employeeId
          shiftAlias {
            id
            value
          }
          data
          type
        }
        shiftToSwapWith {
          id
          code
          date
          employeeId
          shiftAlias {
            id
            value
          }
          data
          type
        }
      }
    }
  }
`;
